export const environment = {
    production: true,
    apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlkcnFkbWRxdHRvbGdjeXBhbmN0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjQzNjA5NTUsImV4cCI6MTk3OTkzNjk1NX0.KyraLBRhLswe_7UBaM1LN0lK1JXUOUWXal1KBKV84-c",
    apiUrl: "https://ydrqdmdqttolgcypanct.supabase.co",
    shortName: "BoS",
    longName: "Blasorchester",
    showTeachers: false,
    symphonyImage: false,
    withExcuses: true,
    withSignout: true,
    isChoir: false,
};